import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import colors from '../../theme/colors';

const infoWrapper = {
    maxWidth: '1440px',
    width: '100%',
    minHeight: '440px',
    padding: '60px 136px 30px',
};
const gridItemWrapper = {
    minHeight: '390px',
    padding: '40px',
};
const gridItemContainer = {
    height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly',
};
const violetContainer = {
    background: `linear-gradient(180deg, #26192C -31.93%, ${colors.violet} 54.16%)`,
    border: '2px solid transparent',
    borderRadius: '10px',
};
const centeredContainer = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
};

const serviceIntroductionData = {
    title: 'Custom Images',
    firstParagraph: 'Servicio de imágenes para crear tus máquinas virtuales al instante. Agilizá tus despliegues con imágenes a medida exclusivas para vos.',
    gatsbyImg: (<StaticImage src="../../images/custom_images_icon.svg" alt="Nube" />),
    isButton: true,
    buttonText: 'Contactanos',
    buttonLinkTo: '/contact_us',
};

const infoData = [
    {
        image: (<StaticImage src="../../images/os-image_icon.svg" alt="Velocidad" />),
        text: 'Pueden ser una imagen de un sistema operativo con un software específico instalado.',
    },
    {
        image: (<StaticImage src="../../images/os_icon.svg" alt="Velocidad" />),
        text: 'Pueden ser una imagen de un sistema operativo base.',
    },
    {
        image: (<StaticImage src="../../images/webpage_icon.svg" alt="Velocidad" />),
        text: 'Puede ser una imagen de una aplicación propia.',
    },
];

const CustomImages = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                background: colors.darkViolet,
            }}
        >
            <Box
                sx={(theme) => ({ [theme.breakpoints.down('lg')]: { padding: '24px' }, ...infoWrapper })}
            >
                <Grid container>
                    <Grid item xs={12} md={4} sx={{ ...gridItemWrapper, ...violetContainer }}>
                        <StaticImage src="../../images/dead-page_icon.svg" alt="Velocidad" />
                        <Typography variant="h3" mt={4}>
                            Si la imagen que necesitás no está en nuestro repositorio público de imágenes, ¡Nosotros la cargamos!
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { padding: '40px 20px 0px' }, ...gridItemWrapper })}
                    >
                        <Typography variant="h3" sx={(theme) => ({ [theme.breakpoints.down('sm')]: { paddingBottom: '24px', ...centeredContainer } })}>
                            Casos para subir
                        </Typography>
                        <Box sx={{ ...gridItemContainer }}>
                            {infoData.map((item) => (
                                <Grid container sx={{ ...centeredContainer }} key={item.text}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={2}
                                        sx={(theme) => ({ [theme.breakpoints.down('sm')]: { paddingBottom: '14px' }, ...centeredContainer })}
                                    >
                                        {item.image}
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography>
                                            {item.text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <HomeConsultation />
    </Layout>
);

export default CustomImages;
